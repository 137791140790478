import { manifestMeta } from 'theme/meta/base';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import { RobotsEnum } from './types'
import { htmlDecode } from '@vue-storefront/core/filters';
import config from 'config';
import store from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import { exclusionUrlsListUa, exclusionUrlsListRu } from 'theme/meta/resource/exclusionUrlsList'
import { getProductImagePath } from 'theme/helpers';
import { getThumbnailPath } from '@vue-storefront/core/helpers';

const getPriceFilter = () => {
  let priceSelected = []
  const route = store.getters['url/getCurrentRoute']
  if (route.query.price === undefined) return

  if (Array.isArray(route.query.price)) {
    priceSelected = route.query.price[0].split('-')
  } else {
    priceSelected = route.query.price.split('-')
  }

  return `${i18n.t('Price')}, грн ${priceSelected[0]}-${priceSelected[1]}`
}

const getFilters = () => {
  const searchQuery = store.getters['special-offers/getCurrentSearchQuery']
  const route = store.getters['url/getCurrentRoute']
  const result = []

  if (searchQuery.filters !== undefined) {
    for (const key of Object.keys(searchQuery.filters)) {
      if (Array.isArray(searchQuery.filters[key])) {
        searchQuery.filters[key].forEach(e => {
          result.push({ filterType: key, filterData: e })
        })
      }
    }
  }

  if (route.params.has_promotion_in_stores !== undefined) {
    result.push({ filterType: 'has_promotion_in_stores' })
  }

  if (searchQuery['sort'] !== undefined) {
    result.push({ filterType: 'sort', sortType: searchQuery['sort'] })
  }
  return result
}

const getSortMetaData = (sortType = '', filtersLength: number) => {
  const currentCategory = store.getters['special-offers/getSpecialOffersCategory']
  const sortMetaData = { h1: '', title: '' }

  if (filtersLength > 1) {
    switch (sortType) {
      case 'final_price:asc': sortMetaData.h1 = String(i18n.t('cheaper')); break
      case 'final_price:desc': sortMetaData.h1 = String(i18n.t('expensive')); break
      case 'created_at:desc': sortMetaData.h1 = String(i18n.t('Novelties')).toLowerCase(); break
      default: sortMetaData.h1 = ''
    }
  } else {
    switch (sortType) {
      case 'final_price:asc': sortMetaData.h1 = currentCategory.name + ' (' + i18n.t('cheaper') + ')'; break
      case 'final_price:desc': sortMetaData.h1 = currentCategory.name + ' (' + i18n.t('expensive') + ')'; break
      case 'created_at:desc': sortMetaData.h1 = i18n.t('Novelties') + ': ' + currentCategory.name; break
      default: sortMetaData.h1 = currentCategory.name
    }
  }

  sortMetaData.title = sortMetaData.h1 + ' | VARUS'
  return sortMetaData
}

const getH1List = (filters = []) => {
  const attributes = store.getters['attribute/getAttributeListByCode'];
  const currentCategory = store.getters['category-next/getCurrentCategory'];

  // Use map to process each filter and return an array of strings.
  const listItems = filters.map((e, index) => {
    // Use a function to get the separator.
    const separator = () => (index ? '; ' : '');
    let item = '';

    switch (e.filterType) {
      case 'sort': {
        const sortH1 = getSortMetaData(e.sortType, filters.length)?.h1;
        if (sortH1?.length) item = sortH1;
        break;
      }
      case 'price':
        item = getPriceFilter();
        break;
      case 'has_promotion_in_stores':
        item = i18n.t('Promotion on') + ' ' + currentCategory.name.toLowerCase();
        break;
      default:
        if (attributes[e.filterType]) {
          const filterLabel = attributes[e.filterType].frontend_label;
          const filterValue = e.filterData.label;
          // Check if the label already exists in the list to avoid duplication.
          item = filterLabel + ' - ' + filterValue;
        }
    }

    // Prepend separator if item is not empty and it's not the first element.
    return item ? separator() + item : '';
  });

  // Join the array into a string, filtering out empty values.
  return listItems.filter(item => item.length).join('');
};

const getIsUlrInExclusionList = () => {
  return [...exclusionUrlsListUa, ...exclusionUrlsListRu]
    .find(e => e.url === store.getters['url/getCurrentRoute'].fullPath)
}

export function getMetaContent (category = null) {
  const currentCategory = category || store.getters['special-offers/getSpecialOffersCategory'] || {}
  const attributes = store.getters['attribute/getAttributeListByCode']

  let h1 = htmlDecode(currentCategory.meta_h1)
  let additional = i18n.t('META_ADDITIONAL_TITLE') as string
  let description = htmlDecode(currentCategory.meta_description)
  const filters = getFilters() || []

  // no filters
  if (!filters.length) {
    h1 = h1 || currentCategory.name
  }

  // only one filter
  if (filters.length === 1) {
    const firstFilter = filters[0]
    switch (firstFilter.filterType) {
      case 'pim_brand_id':
        h1 = h1 || (currentCategory.name + ' ' + firstFilter.filterData.label)
        additional = i18n.t('META_ADDITIONAL_TITLE_BRANDS') as string

        break
      case 'sort':
        const sortMetaData = getSortMetaData(firstFilter.sortType, filters.length)
        h1 = h1 || sortMetaData.h1
        break
      case 'has_promotion_in_stores':
        h1 = h1 || (i18n.t('Promotion on') + ' ' + currentCategory.name.toLowerCase())
        break
      case 'price':
        const priceFilter = getPriceFilter()
        if (priceFilter === undefined) {
          h1 = h1 || (currentCategory.name)
          break
        }
        h1 = h1 || (currentCategory.name + ': ' + priceFilter)
        break
      // all other types of filters
      default:
        h1 = h1 || (currentCategory.name + ': ' + attributes[firstFilter.filterType].frontend_label + ' - ' + firstFilter.filterData.label)
    }
  }

  // multiple filters
  if (filters.length > 1) {
    h1 = currentCategory.name + ': ' + getH1List(filters)
  }

  let title = `${h1} ${additional}`
  description = description || h1 + ' ' + i18n.t('META_DESCRIPTION')

  // url in exclusionUrlsList
  const inExclusionListUrl = getIsUlrInExclusionList()
  const isUrlInExclusionList = !!inExclusionListUrl

  if (inExclusionListUrl) {
    h1 = inExclusionListUrl.h1
    title = inExclusionListUrl.title
  }

  return { h1, title, description, filters, isUrlInExclusionList }
}

const getProductImages = (products) => {
  return products?.map(product => {
    return {
      image: getProductImagePath(product.sku, 300, 300)
    }
  });
}
const getProductImagesForPreload = (products) => {
  const productsForPreload = products.slice(0, 4)
  return getProductImages(productsForPreload)
}

const getProductImagesPreloadLinks = (products) => {
  const images = getProductImagesForPreload(products)
  return images.map((image) => {
    return {
      rel: 'preload',
      href: image.image,
      as: 'image'
    }
  })
}

export function metaCategory () {
  const i18nStore = config.storeViews?.[this.$store.state.storeView.storeCode]?.i18n
  const currentLocale = i18nStore?.defaultLocale;
  const baseUrl = this.$store.getters['siteData/baseUrl'];
  const url = `${baseUrl}/${this.getCurrentCategory?.url_path}`;
  const manifest = manifestMeta();
  const firstBanner = this.banners[0];
  let relRobots;

  if (this.$route?.params.page || this.$route?.query.page) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEX, config.xEnv.key)
  }

  if (this.$route.query.sort) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  const metaContent = getMetaContent()

  const commonCount: any = Object.keys(this.getCurrentFilters).reduce((a, c) => {
    const length = this.getCurrentFilters[c].length
    a[c] = length
    a._commonLength += length

    return a
  }, {
    _commonLength: 0
  })

  const isBrandFilter = (commonCount._commonLength === 1 && commonCount?.pim_brand_id === 1)

  if (!isBrandFilter && commonCount._commonLength >= 1) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  if (getIsUlrInExclusionList()) {
    relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);
  }

  const isRelRobots = relRobots || {}

  const products = this.getProducts
  const preloadProductImages = getProductImagesPreloadLinks(products)

  const meta = {
    title: metaContent.title,
    meta: {
      ...isRelRobots,
      title: {
        name: 'title',
        content: metaContent.title
      },
      description: {
        name: 'description',
        content: metaContent.description
      },
      ogType: {
        property: 'og:type',
        content: 'article'
      },
      ogTitle: {
        name: 'og:title',
        content: metaContent.title
      },
      ogDescription: {
        name: 'og:description',
        content: metaContent.description
      },
      ogUrl: {
        name: 'og:url',
        content: url
      },
      ogLocale: {
        name: 'og:locale',
        content: currentLocale
      }
    },
    link: [
      // temporarily hidden according to task VAR-3141
      // {
      //   rel: 'canonical',
      //   href: url
      // },
      {
        rel: 'preload',
        href: getThumbnailPath(firstBanner?.image_mobile) || getThumbnailPath(firstBanner?.image_desktop) || '',
        as: 'image'
      },
      ...preloadProductImages,
      ...manifest.link
    ]
  };

  return mergeMeta(meta)
}
